<script setup lang='ts'>
import DashboardHeader from '@/views/dashboard/blocks/header/DashboardHeader.vue'
import { useRoute, useRouter } from 'vue-router'
import { nextTick, onMounted, ref } from 'vue'
import { useAuthStore } from '@/stores/auth'
import { storeToRefs } from 'pinia'
import { useSchoolStore } from '@/stores/school'
import { useProfileStore } from '@/stores/profile'

// get props from route
const route = useRoute()
const router = useRouter()
const schoolId = Number(route.params.schoolId)
const groupId = Number(route.params.groupId)
const { isTeacher } = storeToRefs(useAuthStore())
const { schoolList } = storeToRefs(useSchoolStore())
const { me } = storeToRefs(useProfileStore())

const loading = ref(true)

onMounted(() => {
    const defaultClassroom = schoolList.value[0].classrooms.find((classroom) => {
      const classroomMonitors = classroom.monitors.map((monitor) => monitor.identifier)
      if (me.value && me.value.identifier)
        return classroomMonitors.includes(me.value.identifier)
    })


    if (isTeacher.value && defaultClassroom) {
      router.push({
        name: 'dashboard-group',
        params: { schoolId: schoolList.value[0].id, groupId: defaultClassroom.id }
      })
    }

    nextTick(() => {
      loading.value = false
    })
})

</script>


<template>
  <dashboard-header title='Tableau de bord' :school-id='schoolId' :group-id='groupId' />
  <div
    class="col-12 p-5 pt-2 bg-bo-brand-tertiary flex-1 overflow-y-auto gap-5 flex flex-column"
  >
    <router-view v-if='!loading' />
  </div>
</template>
