import { z } from 'zod';
import { Gender, Role } from '@/assets/types/UserTypes'

export const SchoolUserSchema = z.object({
  identifier: z.string(),
  firstname: z.string(),
  lastname: z.string(),
  roles: z.array(z.nativeEnum(Role.Values)) ,
  gender: z.array(z.nativeEnum(Gender.Values)).optional().nullable(),
});

export type SchoolUserEntity = z.infer<typeof SchoolUserSchema>;