<script setup lang='ts'>
// Vue, Vue Router, Pinia
import { computed, ref } from 'vue'
import { useRoute } from 'vue-router'
import { storeToRefs } from 'pinia'

// Stores
import { useSchoolClassroomStore } from '@/stores/schoolClassroom'
import { useLearningCoursesStore } from '@/stores/learningCourses'

// Components
import PTag from 'primevue/tag'
import { useAuthStore } from '@/stores/auth'


/*
*
*  SERVICES
*
* */
const route = useRoute()

// Data, refs
const Header = ref<HTMLElement | null>(null)
const { classroom } = storeToRefs(useSchoolClassroomStore())
const { learningCourse } = storeToRefs(useLearningCoursesStore())
const { isAdmin, isTeacher } = storeToRefs(useAuthStore())

// Props
const props = defineProps<{
  title: string
  schoolId: number
  groupId: number
}>()

const breadcrumbs = computed(() => {
  const baseBreadcrumb = {
    dashboard: {
      name: "dashboard",
      link: route.name !== "dashboard",
      title: "Tableau de bord",
      icon: "pi pi-home"
    },
    group: {
      name: "dashboard-group",
      link: route.name === "dashboard-group-progress",
      title: `Classe (${classroom.value?.data?.name || ""})`,
      icon: "pi pi-chevron-right"
    },
    progress: {
      name: "dashboard-group-progress",
      link: false,
      title: "Résultat du parcours",
      icon: "pi pi-chevron-right"
    }
  };

  switch (route.name) {
    case "dashboard":
      return [baseBreadcrumb.dashboard];

    case "dashboard-group":
      if (isAdmin.value) {
        return [baseBreadcrumb.dashboard, baseBreadcrumb.group];
      }
      if (!isAdmin.value && isTeacher.value) {
        baseBreadcrumb.group.link = false;
        baseBreadcrumb.group.icon = "pi pi-home";
        return [ baseBreadcrumb.group];
      }
      return [];

    case "dashboard-group-progress":
      if (isAdmin.value) {
        return [baseBreadcrumb.dashboard, baseBreadcrumb.group, baseBreadcrumb.progress];
      }
      if (!isAdmin.value && isTeacher.value) {
        baseBreadcrumb.progress.link = false;
        baseBreadcrumb.group.icon = "pi pi-home";
        return [baseBreadcrumb.group, baseBreadcrumb.progress];
      }
      return [];

    default:
      return [];
  }
});


const displayBreadcrumbs = computed(() => {
  if (route.name === 'dashboard') {
    return false
  }

  return true
})

const viewTitle = computed(() => {

  if (route.name === 'dashboard') {
    return 'Liste des groupes'
  }

  if (route.name === 'dashboard-group') {

    return `Classe (${classroom.value.data.name})`
  }

  if (route.name === 'dashboard-group-progress') {
    return `Résultats du parcours`
  }

  return ''
})
</script>

<template>
  <div class="col-12 grid gap-3 grid-nogutter px-5 py-3 border-300 bg-bo-brand-tertiary" ref="Header">
    <!--  Breadcrumbs -->
    <div class="col-12 justify-content-start align-items-center flex gap-2" v-if='displayBreadcrumbs'>
      <template
        v-for="(breadcrumb, index) in breadcrumbs"
        :key="index"
      >
        <component
          :is='breadcrumb.link ? "router-link" : "p"'
          :to="{ name: breadcrumb.name }"
          :class="breadcrumb.link ? 'text-600' : 'text-900'"
          class='no-underline '
        >
          <i :class='breadcrumb.icon' />
          {{ breadcrumb.title }}
        </component>
      </template>

    </div>

    <h3 class="brand-heading uppercase text-left col-12">{{ viewTitle }}</h3>

    <h4 v-if='route.name === "dashboard-group-progress"' class=" brand-heading medium uppercase py-1 align-self-start" rounded
      >{{ learningCourse.title }}
    </h4>
  </div>

</template>