type Constructor<T> = new (...args: never[]) => T;

export default class DependencyContainer {
  // @ts-nocheck
  private static services = new WeakMap<Constructor<unknown>, unknown>();

  // Méthode pour enregistrer une instance avec le constructeur comme clé
  public static register<T>(constructor: Constructor<T>, instance: T): void {
    this.services.set(constructor, instance);
    console.log('Service registered:', constructor.name);
  }

  // Méthode resolve en utilisant le type constructeur
  public static resolve<T>(constructor: Constructor<T>): T {
    const instance = this.services.get(constructor);
    if (!instance) {
      throw new Error(`Service not found for type: ${constructor.name}`);
    }
    return instance as T;
  }
}
