import type { SchoolEntity, SchoolInputEntity } from '@/core/infrastructure/schemas/school/core/SchoolSchema'
import type SchoolClassroom from '@/core/domain/school/SchoolClassroom'
import type SchoolMonitoringGroup from '@/core/domain/school/SchoolMonitoringGroup'
import type SchoolUser from '@/core/domain/school/SchoolUser'
import { slugify } from '@/assets/utils'

/**
 * School domain class
 */

export default class School {
   readonly id: number;
   readonly name: string;
   readonly codeUai: string;
   readonly address: string | null;
   readonly city: string;
   readonly zipCode: string;
   readonly codeInternal: string;
   readonly department: string | null;
   readonly schoolDistrict: string | null;
   readonly contactEmail: string | null;
   classrooms: SchoolClassroom[];
   readonly monitoringGroups: SchoolMonitoringGroup[];
   readonly users: SchoolUser[];
   readonly slug: string;

  constructor(school: SchoolEntity) {
    this.id = school.id;
    this.name = school.name;
    this.codeUai = school.codeUai;
    this.address = school.address;
    this.city = school.city;
    this.zipCode = school.zipCode;
    this.codeInternal = school.codeInternal;
    this.department = school.department;
    this.schoolDistrict = school.schoolDistrict;
    this.contactEmail = school.contactEmail;
    this.classrooms = school.classrooms;
    this.monitoringGroups = school.monitoringGroups;
    this.users = school.users
    this.slug = slugify(school.name);
  }

  get school() {
    return {
      id: this.id,
      name: this.name,
      codeUai: this.codeUai,
      address: this.address,
      city: this.city,
      zipCode: this.zipCode,
      codeInternal: this.codeInternal,
      department: this.department,
      schoolDistrict: this.schoolDistrict,
      contactEmail: this.contactEmail,
      classrooms: this.classrooms,
      monitoringGroups: this.monitoringGroups,
      users: this.users,
    };
  }
}

export class SchoolInput {
  name: string;
  codeUai: string;
  address: string | null;
  city: string;
  zipCode: string;
  codeInternal: string;
  department: string | null;
  schoolDistrict: string | null;
  email: string | null;

  constructor(schoolInput?: SchoolInputEntity) {
    this.name = schoolInput?.name || '';
    this.codeUai = schoolInput?.codeUai || '';
    this.address = schoolInput?.address || null;
    this.city = schoolInput?.city || '';
    this.zipCode = schoolInput?.zipCode || '';
    this.codeInternal = '';
    this.department = schoolInput?.department || null;
    this.schoolDistrict = schoolInput?.schoolDistrict || null;
    this.email = schoolInput?.email || null;
  }

  get schoolInput () {
    return{
      name: this.name,
      codeUai: this.codeUai,
      address: this.address,
      city: this.city,
      zipCode: this.zipCode,
      codeInternal: this.codeInternal,
      department: this.department,
      schoolDistrict: this.schoolDistrict,
      contactEmail: this.email,
    }
  }
}