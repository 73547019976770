<script setup lang="ts">
import { default as PTabMenu } from 'primevue/tabmenu'
import { useSchoolStore } from '@/stores/school'
import { computed, onMounted, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import SchoolCreate from '@/components/schools/SchoolCreate.vue'

const router = useRouter()
const route = useRoute()

const props = defineProps<{
  loading: boolean
}>()


const activeTab = ref('Informations générales')

const items = ref([
  createTabItem('Informations générales', false),
  createTabItem('Périodes scolaires'),
  createTabItem('Gestion des utilisateurs'),
  createTabItem('Gestion des classes et groupes'),
  createTabItem('Gestion des parcours')
])

// METHODS
const onTabChange = (e: string) => {
  activeTab.value = e
  router.push({ query: { tab: e } })
}

function createTabItem(label: string, disabled = true) {
  return {
    label,
    command : () => {
      onTabChange(label)
    },
    disabled: disabled
  }
}

// COMPUTED
const tabDisplay = computed(() => {
  switch (activeTab.value) {
    case 'Informations générales':
      return SchoolCreate
    case 'Périodes scolaires':
      return 'div'
    case 'Gestion des utilisateurs':
      return 'div'
    case 'Gestion des classes et groupes':
      return 'div'
    case 'Gestion des parcours':
      return 'div'
    default:
      return 'div'
  }
})

onMounted(() => {
  if (route.query.tab) {
    activeTab.value = router.currentRoute.value.query.tab as string
  } else {
    router.push({ query: { tab: activeTab.value } })
  }
})
</script>

<template>
    <div>
      <p-tab-menu :model='items'  />
    </div>

    <component :is='tabDisplay' :loading='loading' />
</template>