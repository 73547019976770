<script setup lang='ts'>
import BaseBlock from '@/components/base/BaseBlock.vue'
import { default as PButton } from 'primevue/button'
import { computed, onMounted, type Ref, ref } from 'vue'
import type SchoolPeriod from '@/core/domain/school/SchoolPeriod'
import DependencyContainer from '@/presentation/configuration/DependencyContainer'
import FetchAllSchoolPeriodsUseCase from '@/core/useCases/schoolPeriod/FetchAllSchoolPeriodsUseCase'
import { useSchoolStore } from '@/stores/school'
import { storeToRefs } from 'pinia'
import type School from '@/core/domain/school/School'
import SchoolPeriodsItem from '@/components/schools/SchoolPeriodsItem.vue'

const createSchoolPeriod = () => {
  console.log('CREATE SCHOOL PERIOD')
}

const { getSchoolBySlug } = useSchoolStore()

const schoolPeriods: Ref<SchoolPeriod[]> = ref([])

const props = defineProps<{
  school: School
}>()


const fetchSchoolPeriods = async () => {
  try {
    // Set flags


    schoolPeriods.value = await DependencyContainer.resolve(FetchAllSchoolPeriodsUseCase).execute(props.school.id)
  } catch (error) {
    return Promise.reject(error)
  }
}

onMounted(async () => {
  await fetchSchoolPeriods()
})

</script>

<template>
  <base-block :accordion='false' title='Périodes scolaires'>
    <p-button label='Associer à une année scolaire' icon='pi pi-plus' severity='primary' size='small' @click='createSchoolPeriod' />

    <div v-if='schoolPeriods.length > 0' class='grid  w-full'>
      <div class='col-6 lg:col-3'>
      <school-periods-item
        v-for='(schoolPeriod, index) in schoolPeriods'
        :school-period='schoolPeriod'
        :key='index' />
      </div>
    </div>
  </base-block>
</template>
