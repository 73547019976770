import { z } from 'zod';
import { SchoolClassroomSchema } from '@/core/infrastructure/schemas/school/classroom/SchoolClassroomSchema'
import { SchoolMonitoringGroupSchema } from '@/core/infrastructure/schemas/school/group/SchoolMonitoringGroup'
import { SchoolUserSchema } from '@/core/infrastructure/schemas/school/user/SchoolUserSchema'

export const SchoolSchema = z.object({
  id: z.number(),
  codeUai: z.string(),
  name: z.string(),
  city: z.string(),
  zipCode: z.string(),
  codeInternal: z.string(),
  address: z.string().nullable(),
  department: z.string().nullable(),
  schoolDistrict: z.string().nullable(),
  contactEmail: z.string().nullable(),
  classrooms: z.array(SchoolClassroomSchema),
  monitoringGroups: z.array(SchoolMonitoringGroupSchema),
  users: z.array(SchoolUserSchema),
});

export const SchoolsListSchema = z.array(SchoolSchema);

export const SchoolInputSchema = z.object({
  codeUai: z.string(),
  name: z.string(),
  city: z.string(),
  zipCode: z.string(),
  address: z.string().nullable(),
  department: z.string().nullable(),
  schoolDistrict: z.string().nullable(),
  email: z.string().nullable(),
})
export const SchoolInputListSchema = z.array(SchoolInputSchema);

export type SchoolEntity = z.infer<typeof SchoolSchema>;
export type SchoolInputEntity = z.infer<typeof SchoolInputSchema>;
export type SchoolsListEntity = z.infer<typeof SchoolsListSchema>;
