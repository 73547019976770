import type ISchoolRepository from '@/core/domain/school/ISchoolRepository'
import School, { SchoolInput } from '@/core/domain/school/School'
import type ApiService from '@/core/infrastructure/services/ApiService'
import {
  SchoolInputListSchema,
  SchoolInputSchema,
  SchoolSchema,
  SchoolsListSchema
} from '@/core/infrastructure/schemas/school/core/SchoolSchema'

export default class SchoolRepository implements ISchoolRepository {
  private apiService: ApiService;

  constructor(apiService: ApiService) {
    this.apiService = apiService;
  }

  async fetchSchool(schoolId: number): Promise<School> {
    const response = await this.apiService.get<School>(`admin/schools/${schoolId}`);

    const parse = SchoolSchema.safeParse(response);

    if (parse.success) {
      return new School(parse.data);
    }

    return Promise.reject('Parsing error');
  }

  async fetchSchoolsForMe(): Promise<School[]> {
    const response = await this.apiService.get<School[]>('admin/schools/me');

    console.log(response);

    const parse = SchoolsListSchema.safeParse(response);
    console.log(parse);

    if (parse.success) {
      return parse.data.map((school) => new School(school));
    }

    return Promise.reject('Parsing error');
  }

  async fetchAllSchools(): Promise<School[]> {
    const response = await this.apiService.get<School[]>('admin/schools');

    console.log(response);

    const parse = SchoolsListSchema.safeParse(response);
    console.log(parse);

    if (parse.success) {
      return parse.data.map((school) => new School(school));
    }

    return Promise.reject('Parsing error');
  }

  async fetchSchoolFromDataEducation(codeUai: string): Promise<SchoolInput[]> {
    const response = await this.apiService.get<SchoolInput[]>(`admin/schools/directory/${codeUai}`);

    console.log(response);

    const parse = SchoolInputListSchema.safeParse(response);
    console.log(parse);

    if (parse.success) {
      return parse.data.map((school) => new SchoolInput(school));
    }

    return Promise.reject('Parsing error');
  }

  async createSchool(school: SchoolInput): Promise<School> {
    const response = await this.apiService.post<School>('admin/schools', school.schoolInput);

    console.log(response)

    const parse = SchoolSchema.safeParse(response);
    console.log(parse);

    if (parse.success) {
      return new School(parse.data);
    }

    return Promise.reject('Parsing error');
  }

  updateSchool(): Promise<School> {
    throw new Error('Method not implemented.');
  }
}