import { defineStore, storeToRefs } from 'pinia'
import { computed, ref } from 'vue'
import { useAuthStore } from '@/stores/auth'
import { NabooError } from '@/assets/classes/Error'
import { useProfileStore } from '@/stores/profile'
import DependencyContainer from '@/presentation/configuration/DependencyContainer'
import FetchAllSchoolsUseCase from '@/core/useCases/school/FetchAllSchoolsUseCase'
import School, { SchoolInput } from '@/core/domain/school/School'
import FetchSchoolsForMeUseCase from '@/core/useCases/school/FetchSchoolsForMeUseCase'
import FetchSchoolFromDataEducationUseCase from '@/core/useCases/school/FetchSchoolFromDataEducationUseCase'
import CreateSchoolUseCase from '@/core/useCases/school/CreateSchoolUseCase'
import { useToast } from 'primevue/usetoast'


export const useSchoolStore = defineStore('School', () => {
  // STORES
  const { isAdmin, isPerdir, isCpe, isTeacher } = storeToRefs(useAuthStore())
  const { me } = storeToRefs(useProfileStore())
  const toast = useToast()

  // STATE
  const loading = ref(false)
  const hasError = ref(false)
  const errorMessage = ref('')
  const schoolList = ref<School[]>([])

  // ACTIONS
  /**
   * Fetch schools depending on the user role
   */
  async function fetchSchools() {
    if (isAdmin.value) {
      await fetchAllSchools()
    } else if (isPerdir.value || isCpe.value || isTeacher.value) {
      await fetchSchoolsForMe()
    } else {
      schoolList.value = []
    }
    return Promise.resolve()
  }

  /**
   * Fetch all schools, only for admin
   */
  async function fetchAllSchools() {
    try {
      // Set flags
      loading.value = true
      hasError.value = false
      errorMessage.value = ''

      // Fetch data
      schoolList.value = await DependencyContainer.resolve(FetchAllSchoolsUseCase).execute()
    } catch (error) {
      hasError.value = true
      if (error instanceof NabooError) {
        errorMessage.value = error.message
      }
      return Promise.reject(error)
    } finally {
      loading.value = false
    }
  }

  /**
   * Fetch schools for the current user, usually for teachers
   */
  async function fetchSchoolsForMe() {
    try {
      // Set flags
      loading.value = true
      hasError.value = false
      errorMessage.value = ''

      // Fetch data
      const res: School[] = await DependencyContainer.resolve(FetchSchoolsForMeUseCase).execute()

      // Set classroom monitored in first position
      res.forEach((school) => {
        const monitoredClassrooms = school.classrooms.filter((classroom) => {
          const classroomMonitors = classroom.monitors.map((monitor) => monitor.identifier)
          if (me.value && me.value.identifier)
            return classroomMonitors.includes(me.value.identifier)
        })
        if (monitoredClassrooms.length > 0) {
          school.classrooms = monitoredClassrooms.concat(
            school.classrooms.filter((classroom) => !monitoredClassrooms.includes(classroom))
          )
        }
      })


      // Set data
      if (res) schoolList.value = res
      return Promise.resolve()
    } catch (error) {
      hasError.value = true
      if (error instanceof NabooError) {
        errorMessage.value = error.message
      }
    } finally {
      loading.value = false
    }
  }

  /**
   * Fetch schools from the data education API using the UAI code
   */
  async function fetchSchoolFromDataEducation(codeUai: string) {
    try {
      // Set flags
      loading.value = true
      hasError.value = false
      errorMessage.value = ''

      // Fetch data
      const res = await DependencyContainer.resolve(FetchSchoolFromDataEducationUseCase).execute(codeUai)

      console.log(res)

      if (res.length > 0)
        toast.add({
          severity: 'success',
          summary: 'Établissement trouvé',
          detail: 'L\'établissement a bien été trouvé dans la base de données de l\'éducation nationale.',
          life: 5000
        })

      return res
    } catch (error) {
      hasError.value = true
      if (error instanceof NabooError) {
        errorMessage.value = error.message
      }
      return Promise.reject(error)
    } finally {
      loading.value = false
    }
  }

  async function createSchool(school: SchoolInput) {
    try {
      // Set flags
      loading.value = true
      hasError.value = false
      errorMessage.value = ''

      // Fetch data
      const res = await DependencyContainer.resolve(CreateSchoolUseCase).execute(school)

      // Set data
      if (res) {
        schoolList.value.push(res)
        toast.add({
          severity: 'success',
          summary: 'Établissement créé',
          detail: 'L\'établissement a bien été créé.',
          life: 5000
        })
      }

      return res
    } catch (error) {
      hasError.value = true
      if (error instanceof NabooError) {
        errorMessage.value = error.message
      }
      return Promise.reject(error)
    } finally {
      loading.value = false
    }
  }

  // GETTERS (COMPUTED)
  const getSchoolById = computed(() => {
    return (id: number) => {
      return schoolList.value.find((school) => school.id === id)
    }
  })

  const getSchoolBySlug = computed(() => {
    return (slug: string) => {
      return schoolList.value.find((school) => school.slug === slug)
    }
  })

  const getSchoolByCodeUai = computed(() => {
    return (codeUai: string) => {
      return schoolList.value.find((school) => school.codeUai === codeUai)
    }
  })

  return {
    schoolList,
    fetchSchools,
    fetchSchoolFromDataEducation,
    createSchool,
    getSchoolById,
    getSchoolBySlug,
    getSchoolByCodeUai,
    schoolStoreLoading : loading
  }
})
