<script lang='ts' setup>
import { computed, type ComputedRef, onMounted, type Ref, ref } from 'vue'
import { storeToRefs } from 'pinia'
import { useSchoolStore } from '@/stores/school'
import BaseHeader from '@/components/base/BaseHeader.vue'
import { useRoute } from 'vue-router'

// Composables
const route = useRoute()

// School Store
const { schoolStoreLoading } = storeToRefs(useSchoolStore())
const { fetchSchools, getSchoolBySlug } = useSchoolStore()

// Refs
const componentLoading: Ref<boolean> = ref(true)

onMounted(async () => {
 await fetchSchools()
  componentLoading.value = false
})

const loading = computed(() => schoolStoreLoading.value || componentLoading.value)

const displayBreadcrumbs = computed(() => {
    if (typeof route.meta.showBreadCrumbs === 'boolean')
      return route.meta.showBreadCrumbs
    return false
  })

type Breadcrumb = {
  name: string
  link: boolean
  title: string
  icon: string
}

const breadcrumbs: ComputedRef<Breadcrumb[]> = computed(() => {
  return [
    {
      name: 'schools-list',
      link: true,
      title: 'Liste des établissements',
      icon: 'pi pi-home'
    },
    {
      name: route.name as string,
      link: false,
      title: `${routeTitle.value}`,
      icon: 'pi pi-chevron-right'
    },
  ]
})

const routeTitle = computed(() => {
  if (route.name === 'school') {
    const school = getSchoolBySlug(route.params.schoolSlug as string)
    if (school)
      return `${school.name} (${school.zipCode} ${school.city})`
  }

  if (route.name === 'schools-create')
    return 'Créer un établissement'

  return 'établissements'
})

const displayTitle = computed(() => {
  if (route.name === 'school') {
    const school = getSchoolBySlug(route.params.schoolSlug as string)
    if (school)
      return `${school.name} (${school.zipCode} ${school.city})`
  }

  if (route.name === 'schools-create')
    return 'Créer un établissement'

  return 'établissements'
})
</script>

<template>
 <base-header :title='displayTitle' :display-breadcrumbs='displayBreadcrumbs' :breadcrumbs='breadcrumbs' />
  <div class="col-12 p-5 pt-2 bg-bo-brand-tertiary flex-1 overflow-y-auto gap-5 flex flex-column">
    <router-view :loading='loading' />
  </div>
</template>