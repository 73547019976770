<script setup lang="ts">
import SchoolDetails from '@/components/schools/SchoolDetails.vue'
import { default as PTabMenu } from 'primevue/tabmenu'
import { useSchoolStore } from '@/stores/school'
import { computed, onMounted, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import SchoolPeriods from '@/components/schools/SchoolPeriods.vue'

const { getSchoolBySlug } = useSchoolStore()
const router = useRouter()
const route = useRoute()

const props = defineProps<{
  schoolSlug: string
}>()

const activeTab = ref('Informations générales')

const items = ref([
  createTabItem('Informations générales'),
  createTabItem('Périodes scolaires'),
  createTabItem('Gestion des utilisateurs'),
  createTabItem('Gestion des classes et groupes'),
  createTabItem('Gestion des parcours')
])

// METHODS
const onTabChange = (e: string) => {
  activeTab.value = e
  router.push({ query: { tab: e } })
}

function createTabItem(label: string) {
  return {
    label,
      command : () => {
      onTabChange(label)
    }
  }
}

// COMPUTED
const school = computed(() => getSchoolBySlug(props.schoolSlug))
const tabDisplay = computed(() => {
  switch (activeTab.value) {
    case 'Informations générales':
      return SchoolDetails
    case 'Périodes scolaires':
      return SchoolPeriods
    case 'Gestion des utilisateurs':
      return 'div'
    case 'Gestion des classes et groupes':
      return 'div'
    case 'Gestion des parcours':
      return 'div'
    default:
      return 'div'
  }
})

const activeTabIndex = computed(() => {
  return items.value.findIndex((item) => item.label === activeTab.value)
})

onMounted(() => {
  if (route.query.tab) {
    activeTab.value = router.currentRoute.value.query.tab as string
  } else {
    router.push({ query: { tab: activeTab.value } })
  }
})
</script>

<template>
  <template v-if='school'>
    <div>
      <p-tab-menu :model='items' :active-index='activeTabIndex' />
    </div>

    <component :is='tabDisplay' :school='school'/>
  </template>
</template>