
import { SchoolYearSchema } from '@/core/infrastructure/schemas/school/year/SchoolYearSchema'
import { SchoolSchema } from '@/core/infrastructure/schemas/school/core/SchoolSchema'
import { SchoolUserSchema } from '@/core/infrastructure/schemas/school/user/SchoolUserSchema'
import { z } from 'zod'

export const SchoolPeriodSchema = z.object({
  id: z.number(),
  schoolYear: SchoolYearSchema,
  schoolId: z.number(),
  countStudents: z.number(),
  countTeachers: z.number(),
  countClassrooms: z.number(),
})

export const SchoolPeriodsListSchema = z.array(SchoolPeriodSchema);

export type SchoolPeriodEntity = z.infer<typeof SchoolPeriodSchema>;
export type SchoolPeriodsListEntity = z.infer<typeof SchoolPeriodsListSchema>;
