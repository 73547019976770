import type ISchoolPeriodRepository from '@/core/domain/school/ISchoolPeriodRepository'
import SchoolPeriod from '@/core/domain/school/SchoolPeriod'
import type ApiService from '@/core/infrastructure/services/ApiService'
import School from '@/core/domain/school/School'
import { SchoolSchema } from '@/core/infrastructure/schemas/school/core/SchoolSchema'
import { SchoolPeriodsListSchema } from '@/core/infrastructure/schemas/school/period/SchoolPeriodEntity'


export default class SchoolPeriodRepository implements ISchoolPeriodRepository {
  private apiService: ApiService;

  constructor(apiService: ApiService) {
    this.apiService = apiService;
  }

  async fetchAllSchoolPeriods(schoolId:number): Promise<SchoolPeriod[]> {
    const response = await this.apiService.get<SchoolPeriod[]>(`admin/schools/${schoolId}/periods`);

    const parse = SchoolPeriodsListSchema.safeParse(response);

    if (parse.success) {
      return parse.data.map((schoolPeriod) => new SchoolPeriod(schoolPeriod));
    }

    return Promise.reject('Parsing error');
  }

  async fetchSchoolPeriod(): Promise<SchoolPeriod> {
    throw new Error('Method not implemented.');
  }

  async updateSchoolPeriod(): Promise<SchoolPeriod> {
    throw new Error('Method not implemented.');
  }
}