<script lang='ts' setup>
import { default as PDivider } from 'primevue/divider'

// Composables
const router = useRouter()

import type School from '@/core/domain/school/School'
import { useRouter } from 'vue-router'

const props = defineProps<{
  school: School
}>()

const showSchool = () => {
  router.push({
    name: 'school',
    params: {
      schoolSlug: props.school.slug,
    },
  })
}

</script>

<template>

  <div
    class='h-full border-1 border-bo-brand-secondary border-round-xl p-4 overflow-hidden flex flex-column'
  >
    <h4 class='heading medium  mb-3' :title='school.name'>
      {{ school.name ?? 'Nom Nommé' }}
    </h4>

    <div
      class='grid  col-12 gap-2 mb-auto'
    >
      <p >
        {{ school.address ?? 'Adresse non reseignée' }}<br/>
        {{ school.zipCode ?? 'Non spécifié' }} {{ school.city ?? 'Non spécifié' }} ({{ school.department ?? 'Non spécifié' }})
      </p>

      <p>
        <i class='pi pi-home'></i>
        Académie : {{ school.schoolDistrict ?? 'Non spécifié' }}
      </p>
    </div>


    <p-divider class='border-bo-brand-secondary' />

    <a class='ml-auto bo-active-color cursor-pointer' @click='showSchool'>Afficher l'établissement</a>
  </div>

</template>