import ApiService from '@/core/infrastructure/services/ApiService';
import DependencyContainer from '@/presentation/configuration/DependencyContainer';
import SchoolRepository from '@/core/infrastructure/repositories/SchoolRepository'
import FetchSchoolUseCase from '@/core/useCases/school/FetchSchoolUseCase'
import FetchAllSchoolsUseCase from '@/core/useCases/school/FetchAllSchoolsUseCase'
import FetchSchoolFromDataEducationUseCase from '@/core/useCases/school/FetchSchoolFromDataEducationUseCase'
import CreateSchoolUseCase from '@/core/useCases/school/CreateSchoolUseCase'
import SchoolPeriodRepository from '@/core/infrastructure/repositories/SchoolPeriodRepository'
import FetchAllSchoolPeriodsUseCase from '@/core/useCases/schoolPeriod/FetchAllSchoolPeriodsUseCase'


export default class Bootloader {
  public static initialize() {
    console.log('Bootloader initialized');

    // Register services
    const apiService = new ApiService();

    /*
    *
    * School repository and use cases
    *
    * */
    const schoolRepository = new SchoolRepository(apiService);

    const fetchSchoolsUseCase = new FetchSchoolUseCase(schoolRepository);
    const fetchSchoolForMeUseCase = new FetchSchoolUseCase(schoolRepository);
    const fetchAllSchoolsUseCase = new FetchAllSchoolsUseCase(schoolRepository);
    const fetchSchoolFromDataEducationUseCase = new FetchSchoolFromDataEducationUseCase(schoolRepository);
    const createSchoolUseCase = new CreateSchoolUseCase(schoolRepository);

    // Register use cases
    DependencyContainer.register(FetchSchoolUseCase, fetchSchoolsUseCase);
    DependencyContainer.register(FetchSchoolUseCase, fetchSchoolForMeUseCase);
    DependencyContainer.register(FetchAllSchoolsUseCase, fetchAllSchoolsUseCase);
    DependencyContainer.register(FetchSchoolFromDataEducationUseCase, fetchSchoolFromDataEducationUseCase);
    DependencyContainer.register(CreateSchoolUseCase, createSchoolUseCase);

    /*
    *
    *
    * School period repository and use cases
    *
    * */
    const schoolPeriodRepository = new SchoolPeriodRepository(apiService);

    const fetchAllSchoolPeriodsUseCase = new FetchAllSchoolPeriodsUseCase(schoolPeriodRepository);

    // Register use cases
    DependencyContainer.register(FetchAllSchoolPeriodsUseCase, fetchAllSchoolPeriodsUseCase);
  }
}
