import type ISchoolRepository from '@/core/domain/school/ISchoolRepository'
import School, { SchoolInput } from '@/core/domain/school/School'
import { school } from '@/services/API/school'


export default class CreateSchoolUseCase {
  private schoolRepository: ISchoolRepository;

  constructor(schoolRepository: ISchoolRepository) {
    this.schoolRepository = schoolRepository;
  }

  async execute(school: SchoolInput): Promise<School> {
    return await this.schoolRepository.createSchool(school);
  }
}