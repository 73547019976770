import { z } from 'zod';
import { Degree } from '@/assets/types/school/ISchoolClassroom'
import { SchoolYearSchema } from '@/core/infrastructure/schemas/school/year/SchoolYearSchema'
import { SchoolUserSchema } from '@/core/infrastructure/schemas/school/user/SchoolUserSchema'
import { SchoolMonitoringGroupSchema } from '@/core/infrastructure/schemas/school/group/SchoolMonitoringGroup'

export const SchoolClassroomSchema = z.object({
  id: z.number(),
  name: z.string(),
  degree: z.nativeEnum(Degree.Values),
  schoolId: z.number(),
  schoolYear: SchoolYearSchema,
  teachers: z.array(SchoolUserSchema),
  students: z.array(SchoolUserSchema),
  monitors: z.array(SchoolMonitoringGroupSchema),
});

export type SchoolClassroomEntity = z.infer<typeof SchoolClassroomSchema>;