/**
 * School period domain class
 */
import type { SchoolPeriodEntity } from '@/core/infrastructure/schemas/school/period/SchoolPeriodEntity'
import type SchoolYear from '@/core/domain/school/SchoolYear'


export default class SchoolPeriod {
  readonly id: number
  readonly schoolYear: SchoolYear
  readonly schoolId: number
  readonly countStudents: number
  readonly countTeachers: number
  readonly countClassrooms: number


  constructor(period: SchoolPeriodEntity) {
    this.id = period.id
    this.schoolYear = period.schoolYear
    this.schoolId = period.schoolId
    this.countStudents = period.countStudents
    this.countTeachers = period.countTeachers
    this.countClassrooms = period.countClassrooms
  }
}