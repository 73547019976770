import { z } from 'zod';
import { SchoolUserSchema } from '@/core/infrastructure/schemas/school/user/SchoolUserSchema'
import { SchoolYearSchema } from '@/core/infrastructure/schemas/school/year/SchoolYearSchema'

export const SchoolMonitoringGroupSchema = z.object({
  id: z.number(),
  schoolId: z.number(),
  schoolYear: SchoolYearSchema,
  name: z.string(),
  teacher: SchoolUserSchema,
  students: z.array(SchoolUserSchema),
})

export type SchoolMonitoringGroupEntity = z.infer<typeof SchoolMonitoringGroupSchema>;