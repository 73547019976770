export default class ApiError extends Error {
  public statusCode: number | undefined;
  public errorName: string;

  constructor(
    message: string,
    statusCode: number | undefined,
    errorName: string
  ) {
    super(message);
    this.statusCode = statusCode;
    this.errorName = errorName;
  }
}
