<script setup lang="ts">
/* ---------------------
--------IMPORTS---------
----------------------*/
//PrimeVue
import { default as PButton } from 'primevue/button'
import { default as PImage } from 'primevue/image'
import { default as PAvatar } from 'primevue/avatar'
import { default as PMenu } from 'primevue/menu'
import type { MenuItem } from 'primevue/menuitem'

//Vue & Vue Router
import { computed, nextTick, type Ref, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { createNabooAdminRoutes } from '@/router/routes'

// Custom
import largeLogoUrl from '@/assets/images/logo-naboo-bleu.svg'
import smallLogoUrl from '@/assets/images/logo-naboo-bleu-small.svg'
import { useProfileStore } from '@/stores/profile'
import { useAuthStore } from '@/stores/auth'
import { useSchoolStore } from '@/stores/school'
import { storeToRefs } from 'pinia'

// Types
import { Role } from '@/assets/types/UserTypes'

const routes = createNabooAdminRoutes()
const route = useRoute()
const router = useRouter()
const { toggleMenuOpen } = useProfileStore()
const { isMenuOpened, getPicture, getFullName, getFirstNameInitial } =
  storeToRefs(useProfileStore())
const { initLogout } = useAuthStore()
const { getRoles, isTeacher } = storeToRefs(useAuthStore())
const { schoolList } = storeToRefs(useSchoolStore())

// DATA, PROPS & REFS
const isFullyOpened = ref(isMenuOpened.value)

/**
 * Fonction récursive pour parcourir les routes et collecter les rôles
 * @param node
 */
function collectRolesFromRoute(node: any): Role.Values[] {
  // Utiliser un Set pour garantir l'unicité des valeurs
  const rolesSet = new Set<Role.Values>()

  const traverse = (currentNode: any) => {
    // Vérifier si "meta" existe et contient "roles", et que c'est une liste valide
    if (currentNode?.meta?.roles && Array.isArray(currentNode.meta.roles)) {
      currentNode.meta.roles.forEach((role: Role.Values | null | undefined) => {
        if (role) {
          rolesSet.add(role) // Ajouter les rôles non null et non undefined dans le Set
        }
      })
    }

    // Si le noeud a des enfants, les parcourir récursivement
    if (currentNode?.children && Array.isArray(currentNode.children)) {
      currentNode.children.forEach((child: any) => traverse(child))
    }
  }

  // Démarrer la récursion sur le noeud de départ
  traverse(node)

  // Convertir le Set en tableau et retourner la liste unique des rôles
  return Array.from(rolesSet).map((role) => role)
}

/**
 * Fonction pour construire les éléments du menu
 */
const menuItems = computed(() => {
  return routes
    .filter((it) => it.name !== 'NotFound')
    .map((it) => {
      const routeRoles = collectRolesFromRoute(it)
      let children

      if (routeRoles.length > 0 && !routeRoles.some((role) => getRoles.value.includes(role))) {
        return null
      }
      const link = 'name' in it ? it.name : it?.children.length > 0 ? it.children[0].name : ''

      if (link === 'dashboard') {
        children = schoolList.value.map((school) => {
          return {
            name: school.name,
            classrooms: school.classrooms.map((classroom) => {
              return {
                name: classroom.name,
                link: 'dashboard-group',
                params: {
                  schoolId: school.id,
                  groupId: classroom.id,
                },
              }
            })
          }
        })
      }

      return {
        name: it.meta?.menuTitle,
        link: link,
        icon: it.meta?.icon,
        group: it.meta?.group,
        children
      }
    })
    .filter((it) => it !== null)
})

// Avatar menu
const menuWrapper: Ref<HTMLElement | null> = ref(null)
const menuAvatar = ref()
const items: Ref<MenuItem[]> = ref([
  {
    label: 'Se déconnecter',
    command: () => initLogout()
  }
])

// METHODS
async function processClick(link: string, params?: Record<number, number>) {
  if (link === 'dashboard' && isTeacher.value) {
    return
  }

  if (link === '/') {
    await router.replace({ path: '/' })
    return
  }

  if (link === route.name) {
    await router.push({ name: link, params: params })
    return
  }

  await router.push({ name: link, params: params })
}

function toggleMenu() {
  if (!isMenuOpened.value) {
    nextTick(() => {
      isFullyOpened.value = true
    })
  } else {
    nextTick(() => {
      isFullyOpened.value = false
    })
  }
  toggleMenuOpen()
  //isOpened.value = !isOpened.value
}

const toggle = (event: Event) => {
  menuAvatar.value.toggle(event)
}

// COMPUTED PROPERTIES
const logoUrl = computed(() => {
  return isFullyOpened.value ? largeLogoUrl : smallLogoUrl
})

const currentGroup = computed(() => {
  return route?.meta?.group ?? 'home'
})
</script>

<template>
  <div
    class="_m-Lateral-Menu relative overflow-y-auto border-right-1 border-bo-brand-secondary"
    :class="isMenuOpened ? 'opened' : ['closed', 'flex', 'flex-column', 'align-items-center']"
    ref="menuWrapper"
  >
    <div class='sticky top-0 px-4 pt-5 bg-white z-2'>
      <!--Logo-->
      <img v-if="isFullyOpened" :src="logoUrl" class="block w-full h-3rem" alt='Logo naboo' />
      <img v-else :src="logoUrl" class="block max-w-3rem" alt='Logo naboo small' />

      <!--Profile-->
      <p-button
        @click="toggle"
        aria-haspopup="true"
        aria-controls="overlay_menu"
        text
        class="p-0 my-5 w-full border-none"
        :class="isFullyOpened ? 'justify-content-start' : 'justify-content-center'"
      >
        <p-avatar v-if="getPicture" :image="getPicture" shape="circle" />
        <p-avatar v-else :label="getFirstNameInitial?.toLocaleUpperCase()" shape="circle" />
        <span v-if="isFullyOpened" class='ml-2 white-space-nowrap text-left flex-1 max-w-10rem overflow-hidden text-overflow-ellipsis uppercase' :title='getFullName'>{{ getFullName}}</span>
        <i class="pi pi-chevron-down ml-auto" v-if="isFullyOpened"></i>
      </p-button>
      <p-menu ref="menuAvatar" id="overlay_menu" :model="items" :popup="true" />
    </div>

    <!--Menu-->
    <div
      class="_m-Lateral-Menu-Nav flex flex-column gap-4 overflow-y-auto px-4"
      :class="{ 'align-items-center': !isFullyOpened }"
    >
      <template
        v-for="(item, i) in menuItems"
        :key="`link_${i}`"
      >
        <div
          class="_m-Lateral-Menu-Nav__Item flex align-items-start  relative"
          :class="[currentGroup === item.group ? 'selected' : '', { 'has-children': item.children }]"
        >
          <div class='w-full'>
            <div class='flex'>
              <i class="left-0 pi flex justify-content-center align-items-center p-1" :class="[item.icon]" @click="processClick(item.link)" />
              <h4 v-if='isFullyOpened' class='brand-heading small   flex align-items-center uppercase ml-2' :class='{"cursor-pointer" : !isTeacher}' @click="processClick(item.link)">{{ item.name }}</h4>
            </div>

            <template v-if='isFullyOpened'>
              <div @click.prevent v-if='item?.children' class=' flex flex-column gap-2 text ml-4 mt-2' >
                <template v-for='child in item?.children' :key='`child_${child.name}`'>
                  <template v-if='!isTeacher'>
                    <h5 class='pl-2 brand-heading small uppercase white-space-nowrap text-overflow-ellipsis w-full overflow-hidden pl-1' :title='child.name'>{{child.name}}</h5>
                  </template>
                  <template v-for='classroom in child.classrooms' :key='`classroom_${classroom.name}`'>
                    <h6 @click.stop.self='processClick(classroom.link, classroom.params)' class='pl-2 brand-heading small-regular uppercase cursor-pointer pl-1' :class='{ "selected" : Number(route.params.groupId) === classroom.params.groupId }'>{{ classroom.name }}</h6>
                  </template>
                </template>
              </div>
            </template>
          </div>

        </div>
      </template>

    </div>

    <p-button
      @click="toggleMenu()"
      icon="pi pi-arrow-right"
      text
      rounded
      aria-label="toggle menu"
      class="absolute border-none right-0 bottom-0 _m-Lateral-Menu-Toggle"
    />
  </div>
</template>

<style scoped lang="scss">
._m-Lateral-Menu {
  height: 100vh;
  transition: all ease 0.3s;

  // Variants
  &.opened {
    width: 250px;

    .p-button._m-Lateral-Menu-Toggle {
      position: relative;
      transform: rotateZ(180deg);
    }
  }

  &.closed {
    max-width: 105px;
  }

  ._m-Lateral-Menu-Nav {
    ._m-Lateral-Menu-Nav__Item {
      border-radius: 6px;
      transition: all ease 0.3s;

      &:hover {
        cursor: pointer;

        i {
          color: var(--blue-400);
          background-color: var(--gray-100);
        }
      }

      i {
        color: var(--gray-500);
        background-color: transparent;
        border-radius: 6px;
        transition: all ease 0.3s;
      }

      //state of component
      &.selected {
        i {
          color: var(--blue-400);
          background-color: var(--blue-100);
        }
      }

      &.has-children {
        .selected {
          color: var(--blue-400);
          font-weight: 700
        }
      }
    }
  }
}
</style>
