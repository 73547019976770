<script setup lang='ts'>
import BaseBlock from '@/components/base/BaseBlock.vue'
import School, { SchoolInput } from '@/core/domain/school/School'
import { default as PInputText } from 'primevue/inputtext'
import { default as PButton } from 'primevue/button'
import { default as PInlineMessage } from 'primevue/inlinemessage'
import { type Ref, ref } from 'vue'
import { useSchoolStore } from '@/stores/school'
import { useRouter } from 'vue-router'

const router = useRouter()

// Data
const school: Ref<SchoolInput> = ref(new SchoolInput())
const isSchoolRetrieved = ref(false)
const errrorMessage = ref('')

const props = defineProps<{
  loading: boolean
}>()


// Methods
const { fetchSchoolFromDataEducation, createSchool, getSchoolByCodeUai } = useSchoolStore()

const fetchSchool = async () => {
  if (getSchoolByCodeUai(school.value.codeUai)) {
    errrorMessage.value = 'Cet établissement existe déjà'
    return
  }

  const newSchool: SchoolInput[] = await fetchSchoolFromDataEducation(school.value.codeUai)

  console.log(newSchool)
  if (!newSchool || newSchool.length === 0) {
    errrorMessage.value = 'Cet établissement n\'existe pas'
    return
  }
  isSchoolRetrieved.value = true
}

const createSchoolFromDataEducation = async () => {
  const newSchool: School = await createSchool(school.value)

  if (newSchool) {
    await router.push({
      name: 'school',
      params: {
        schoolSlug: newSchool.slug,
      },
    })
  }
}

</script>

<template>
  <base-block :accordion='false' title="Informations de l'établissement">
    <div class='flex flex-column gap-3' >
      <div class='flex flex-column gap-2 align-items-start'>
        <p class='font-semibold'>Code établissement UAI*</p>
        <p-input-text v-model='school.codeUai' />
        <p-button label='Valider le code établissement' outlined severity='primary' @click='fetchSchool' />
        <p-inline-message v-if='errrorMessage' severity='error' >
          {{ errrorMessage }}
        </p-inline-message>
      </div>

      <template v-if='isSchoolRetrieved'>
        <div class='flex flex-column gap-2 align-items-start'>
          <p class='font-semibold'>Nom de l'établissement</p>
          <p-input-text disabled v-model='school.name' />
        </div>

        <div class='flex flex-column gap-2 align-items-start'>
          <p class='font-semibold'>Adresse email</p>
          <p-input-text disabled v-model='school.email' />
        </div>

        <div class='flex flex-column gap-2 align-items-start'>
          <p class='font-semibold'>Adresse</p>
          <p-input-text disabled v-model='school.address' />
        </div>

        <div class='flex gap-2'>
          <div class='flex flex-column gap-2 align-items-start'>
            <p class='font-semibold'>Code postal</p>
            <p-input-text disabled v-model='school.zipCode' />
          </div>

          <div class='flex flex-column gap-2 align-items-start'>
            <p class='font-semibold'>Ville</p>
            <p-input-text disabled v-model='school.city' />
          </div>
        </div>


        <div class='flex flex-column gap-2 align-items-start'>
          <p class='font-semibold'>Département</p>
          <p-input-text disabled v-model='school.department' />
        </div>

        <div class='flex flex-column gap-2 align-items-start'>
          <p class='font-semibold'>Académie</p>
          <p-input-text disabled v-model='school.schoolDistrict' />
        </div>

        <p-button label="Créer l'établissement" class='align-self-start' @click='createSchoolFromDataEducation' />
      </template>

    </div>
  </base-block>
</template>